<template>
  <el-main>
    <el-form :model="ruleForm" class="el-form-search" label-width="130px">
      <el-form-item label="团长姓名：">
        <el-input size="small" v-model="ruleForm.name" placeholder="请输入回收员信息"></el-input>
      </el-form-item>
      <el-form-item label="团长手机号码：">
        <el-input size="small" v-model="ruleForm.tel" placeholder="请输入回收员手机号码"></el-input>
      </el-form-item>
      <el-form-item label="所属店铺：">
        <el-select size="small" v-more="loadMoreType" v-model="ruleForm.store_id" filterable remote :remote-method="getStoreList" placeholder="请选择" class="classify_select">
          <el-option v-for="item in storeSearch.storeList" :key="item.id" :label="item.store_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader size="small" v-model="ruleForm.area_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker v-model="ruleForm.create_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getHeadList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="headerList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="团长信息" align="center">
        <template v-slot="{ row }">
          <UserInfo :name="row.nick_name" :avatar="row.avatar"></UserInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="团长姓名" align="center"></el-table-column>
      <el-table-column prop="tel" label="手机号码" align="center"></el-table-column>
      <el-table-column prop="store_name" label="所属店铺" align="center"></el-table-column>
      <el-table-column prop="order_num" label="累计接单数" align="center"></el-table-column>
      <el-table-column label="累计接单金额" align="center">
        <template v-slot="{ row }">￥{{ row.total_amout }}</template>
      </el-table-column>
      <el-table-column label="累计奖励" align="center">
        <template v-slot="{ row }">￥{{ row.amount }}</template>
      </el-table-column>
      <el-table-column label="注册时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template v-slot="{ row }">
          <el-switch @change="changeStatus(row)" v-model="row.is_open" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="deleteHeader(row)" type="text" v-if="!Number(row.delete_time)">删除</el-button>
          <el-button @click="Recovery(row)" type="text" v-else>恢复</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="headerTotal" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    Paging,
    UserInfo,
  },
  data() {
    return {
      getDateformat,
      ruleForm: {
        page: 1,
        rows: 10,
        name: '',
        tel: '',
        store_id: '',
        create_time: [],
        area_id: 0,
      },
      areaList: [],
      storeSearch: {
        storeName: '',
        storePage: 1,
        storeRows: 10,
        storeTotal: 0,
        storeList: [],
      },
      headerList: [],
      headerTotal: 0,
    };
  },
  created() {
    this.getArea();
    this.getStoreList();
    this.getHeadList();
  },
  // 自定义指令
  directives: {
    more: {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  methods: {
    // 获取地区列表
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    // 门店列表下拉框 滑动加载更多数据
    loadMoreType() {
      if (Math.ceil(this.storeSearch.storeTotal / this.storeSearch.storeRows) > this.storeSearch.storePage) {
        this.storeSearch.storePage++;
        this.getStoreList(this.storeSearch.storeName, 1);
      }
    },
    // 获取门店列表
    getStoreList(name, is_more) {
      if (!is_more) {
        this.storeSearch.storePage = 1;
      }
      this.storeSearch.storeName = name;
      this.$axios
        .post(this.$api.store.storeList, {
          page: this.storeSearch.storePage,
          rows: this.storeSearch.storeRows,
          store_name: name,
        })
        .then(res => {
          if (res.code === 0) {
            if (is_more) {
              this.storeSearch.storeList = this.storeSearch.storeList.concat(res.result.list);
            } else {
              this.storeSearch.storeList = res.result.list;
            }
            this.storeSearch.storeTotal = res.result.total_number;
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.ruleForm.rows = val;
      } else {
        this.ruleForm.page = val;
      }
      this.getHeadList();
    },
    // 获取团长列表
    getHeadList(style) {
      if (style) this.ruleForm.page = 1;
      let obj = {
        page: this.ruleForm.page,
        rows: this.ruleForm.rows,
      };
      if (this.ruleForm.name) obj.name = this.ruleForm.name;
      if (this.ruleForm.tel) obj.tel = this.ruleForm.tel;
      if (this.ruleForm.store_id) obj.store_id = this.ruleForm.store_id;
      if (this.ruleForm.area_id) obj.area_id = this.ruleForm.area_id;
      if (this.ruleForm.create_time?.length) {
        obj.create_time = [Math.floor(this.ruleForm.create_time[0] / 1000), Math.floor(this.ruleForm.create_time[0] / 1000)];
        if (obj.create_time[0] === obj.create_time[1]) obj.create_time[1] += 60 * 60 * 24 - 1;
      }
      this.$axios.post(this.$api.retail.headList, obj).then(res => {
        if (res.code === 0) {
          res.result.list.map(item => (item.is_open = Number(item.is_open)));
          this.headerList = res.result.list;
          this.headerTotal = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 改变团长的启用状态
    changeStatus(data) {
      let is_open = data.is_open ? 0 : 1;
      let str = is_open ? '此操作将禁用该团长' : '此操作将启用该团长';
      this.$confirm(`${str}, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.retail.setOpen, { id: data.id, is_open: is_open ? 0 : 1 });
        })
        .then(res => {
          if (res.code === 0) {
            let msg = is_open ? '成功禁用' : '成功启用';
            this.$message.success(msg);
          } else {
            this.$message.error(res.msg);
            data.is_open = data.is_open ? 0 : 1;
          }
        })
        .catch(() => {
          data.is_open = data.is_open ? 0 : 1;
        });
    },
    // 删除
    deleteHeader(data) {
      this.$confirm(`此操作将删除该团长, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.retail.deleteHeder, { id: data.id });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            data.delete_time = Math.floor(new Date().getTime() / 1000);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 恢复团长
    Recovery(data) {
      this.$confirm(`此操作将恢复该团长, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.retail.recover, { id: data.id });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('恢复成功');
            data.delete_time = 0;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .btn {
    padding: 4px 15px;
    color: #fff;
    background-color: #409eff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    border-radius: 3px;
    user-select: none;
  }
  .Disable {
    background-color: #999999;
  }
}
</style>
